@import "../../styles/baseline.scss";

.palette {
    width: 100%;

    &__row {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    &__colors {
        display: grid;
        //grid-template-columns: repeat(7, 1fr);
        grid-template-columns: repeat(4, 1fr);
        gap: 4px;

        @include tablet {
            grid-template-columns: repeat(7, 1fr);
        }
    }

    &__color {
        width: 100%;
        //min-width: 96px;
        border-radius: 4px;
        height: 96px;
        display: flex;
        flex-direction: column-reverse;
        overflow: hidden;
        border: 1px solid rgb(36, 36, 36);
    }

    &__wrapper {
        width: 100%;
        height: 32px;
        background: $white;
    }
}