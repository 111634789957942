@import "../../styles/baseline.scss";

.tag {
    padding: auto;
    display: flex;
    flex-direction: column;
    align-self: center;

    @include tablet {
        flex-direction: row;
        height: 60px;
    }

    &__card {
        display: flex;
        margin: 1rem 0rem 1rem 0rem;
        padding: auto;
        border-right: 1px solid;
        margin-right: 1.5rem;
        border-right-color: $black;
        align-content: center;
        align-items: center;

        &:last-child {
            border-right: 0px solid;
        }

        @include tablet {
            border-right-color: #ffffff16;
        }
    }

    &__symbol {
        margin-right: 10px;
        padding: auto;
        align-self: center;

        &--type {
            //width: 20px;
            height: 20px;
        }

        &--role {
            //width: 18px;
            height: 18px;
        }

        &--duration {
            //width: 21px;
            height: 20px;
        }
    }

    &__content {
        padding-right: 1.5rem;
        font-weight: bold;
        align-self: center;
    }
}