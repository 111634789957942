@import "../../styles/baseline.scss";

.card {
    margin: 2rem;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 20px;
    padding: 80px 40px;
    border-radius: 15px;

    @include tablet {
        margin: 2rem;
        padding: 60px 40px;
        transition: all 0.2s ease 0s;

        &:hover {
            cursor: pointer;
            box-shadow: rgba(0, 0, 0, 0.18) 0px 20px 30px;
            transition: all 0.2s ease 0s;
        }
    }
 
    & > h3 {
        margin-top: 1rem;
        margin-bottom: 2rem;
        text-align: left;
    }

    & > h4 {
        text-align: center;
        font-size: 0.8125rem;
        display: flex;
        justify-content: space-around;
        line-height: 1.125rem;
        margin: auto 0;
        padding-top: 20px;
        font-weight: bold;
        text-transform: uppercase;
        opacity: 0.8;
        color: $white;
        opacity: 0.65;;
    }
}

