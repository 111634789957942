@import "../../styles/baseline.scss";

.caption {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto 0;
    padding: auto;
    justify-content: center;
    margin-bottom: 2rem;
    //top: -2rem;

    & > span {
        margin: auto;
        width: 100%;
        max-width: 360px;
        justify-self: center;
        text-align: center;
        color: $grey;
        line-height: 1.5;

        &:nth-of-type(1) {
            color: white;
            font-weight: 700;
            line-height: 1.8;
        }

        @include tablet {
            max-width: 434px;
        }
    }

}