@import "../../styles/baseline.scss";

.about {

    &__container {
        display: grid;
        grid-template-areas:    "profile"
                                "content"
                                "redirect";
        //grid-gap: 120px;
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content;
        padding-top: 120px;
        grid-gap: 2rem;

        @include tablet {
            padding-top: 240px;
            grid-gap: 120px;
            grid-template-columns: 400px 1fr;
            grid-template-rows: 1fr min-content;
            grid-template-areas:    "profile content"
                                    "redirect redirect";
        }

        @include laptop {
            grid-template-areas:    "profile content"
                                    "redirect redirect";
        }
    }

    &__profile {
        grid-area: profile;
        width: 100%;
        margin: 0 auto;

        @include tablet {
            width: 400px;
        }
    }

    &__image {
        width: 100%;
        border-radius: 6px;
        height: auto;
    }

    &__content {
        grid-area: content;
        width: 100%;

        & > p, h2 {
            margin-bottom: 1.5rem;
        }
    }

    &__redirect {
        grid-area: redirect;
        width: 100%;
    }
}