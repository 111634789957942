@import "../../styles/baseline.scss";

.framework {
    display: grid;
    grid-template-areas:    'trigger action'
                            'investment reward';
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;
    grid-template-rows: 1fr 1fr;
    height: 500px;
    width: 100%;
    margin: auto;
    background-color: #181818;
    margin: 1.5rem 0rem;

    @include tablet {
        //width: 80%;
        height: 400px;
    }

    &__label, 
    &__sublabel {
        position: absolute;
        z-index: 9;
    }

    &__items {
        display: grid;
        row-gap: 0.5rem;
        width: 100%;
    }

    &__item {
        width: 100%;
        text-align: right;
        height: max-content;
    }

    &__subsection {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: $black;
        align-items: center;
        display: flex;
        padding-right: 1.5rem;

        &--top {
            & > h5 {
                bottom: 0;
                left: 0;
                position: absolute;
                margin-bottom: 0.25rem;
            }
        }

        &--bottom {
            & > h5 {
                top: 0;
                left: 0;
                position: absolute;
                margin-top: 0.25rem;
            }
        }
    }

    &__section {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: $black;
        display: grid;
        align-items: center;

        &--trigger {
            grid-area: trigger;
            grid-template-rows: 1fr 1fr;
            background-color: #181818;
            grid-gap: 4px;

            & > span {
                top: 0;
                left: 0;

            }
        }

        &--action {
            grid-area: action;

            & > span {
                right: 0;
                top: 0;
            }
        }

        &--investment {
            grid-area: investment;
            padding-right: 1.5rem;

            & > span {
                left: 0;
                bottom: 0;
            }
        }

        &--reward {
            grid-area: reward;

            & > span {
                right: 0;
                bottom: 0;
            }
        }
    }
}