@import "../../styles/baseline.scss";

.page {
    width: 100vw;

    &__container {
        margin: 0 16px;

        @include tablet {
            margin: 0 60px;
        }

        @include laptop-small {
            margin: 0 100px;
        }

        @include laptop {
            margin: 0 auto;
            width: 1000px;
            margin-bottom: 2rem
        }
    }
};