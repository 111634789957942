@import "../../styles/baseline.scss";

.footer {
    width: 100vw;
    margin: 4rem auto;

    &__container {
        margin: 0 16px;
        display: grid;
        grid-template-areas:    ' top '
                                ' break '
                                ' bottom';
        row-gap: 2rem;


        @include tablet {
            margin: 0 60px;
        }

        @include laptop-small {
            margin: 0 100px;
        }

        @include laptop {
            width: 1000px;
            margin: 0 auto;
        }
        
    }

    &__top {
        grid-area: top;
        display: grid;
        width: 100%;
        grid-template-areas:    ' wordmark' 
                                ' nav ';
        grid-template-rows: max-content;
        row-gap: 2rem;

        @include tablet {
            grid-template-areas:    ' wordmark nav ';
            grid-template-columns: 1fr max-content;
        }
    }

    &__wordmark {
        display: flex;

        & > h3 {
            color: white;
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }

    &__nav {
        grid-area: nav;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, max-content);
        row-gap: 2rem;
        width: 100%;

        @include tablet {
            margin-top: 0.6rem;
            grid-template-columns: repeat(3, max-content);
            grid-template-rows: max-content;
            column-gap: 3rem;
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        width: 120px;
    }

    &__label {
        color: $white;
        width: 100%;
        display: flex;
        font-size: 1rem;
        padding-bottom: 0.75rem;
        font-weight: bold;
    }

    &__links {
        display: flex;
        flex-direction: column;

        & > li > a {
            color: $grey;
            font-size: 1rem;
            line-height: 2.2rem;
            font-weight: normal;
            cursor: pointer;

            &:hover {
                color: $white;
            }
        }
    }

    &__break {
        grid-area: break;
        height: 1px;
        width: 100%;
        //background-color: $black100;
        background-color: #ffffff16;
    }

    &__bottom {
        grid-area: bottom;
        display: grid;
        height: max-content;
        width: 100%;
        grid-template-areas:    ' socials ' 
                                ' copyright ';
        grid-template-rows: max-content;
        row-gap: 1.5rem;
        align-content: center;

        @include tablet {
            height: 44px;
            grid-template-areas:    ' socials copyright ';
            grid-template-columns: 1fr max-content;
        }
    }

    &__socials {
        display: grid;
        grid-template-columns: repeat(3, max-content);
        padding-inline-start: 0px;
        gap: 3rem;
        justify-content: center;
        align-items: center;

        & > a {
            height: 32px;

            @include tablet {
                height: 24px;
            }
        }

        @include tablet {
            justify-content: flex-start;
        }
    }
    
    &__icon {
        opacity: 0.6;
        width: 32px;
        height: 32px;
        transition: all 0.2s ease 0s;
        fill: $white;

        &:hover {
            opacity: 1;
        }

        @include tablet {
            width: 24px;
            height: 24px;
        }
    }

    &__copyright {
        display: grid;
        grid-auto-flow: column;
        column-gap: 0.5rem;
        width: max-content;
        //height: 44px;
        align-items: center;
        justify-self: center;

        & > span {
            opacity: 0.6;
            color: $white;
        }

        @include tablet {
            justify-self: right;
            text-align: right;
        }
    }

    &__source {
        opacity: 0.6;
        color: $white;

        &:hover {
            opacity: 1;
        }
    }
}