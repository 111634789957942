@import "../../styles/baseline.scss";

.project {

    &__container {
        display: grid;
        padding-top: 120px;
        grid-template-areas:    "content"
                                "redirect";
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content;
    

        @include laptop-small {
            grid-gap: 80px;
            grid-template-columns: 160px 1fr;
            grid-template-rows: 1fr max-content;
            grid-template-areas:    "sidenav content"
                                    "redirect redirect";
        }

        @include laptop {
            padding-top: 240px;
            grid-gap: 120px;
            grid-template-columns: 210px 1fr;
        }
    }

    &__image,
    &__carousel,
    &__preview,
    &__video
    &__model {
        margin-top: 1.5rem;
    }

    &__subtext {
        width: 60%;
        margin: auto;
        justify-self: center;
        text-align: center;
        margin-bottom: 4rem;

        & > strong {
            color: whitesmoke
        }

        @include tablet {
            margin-top: 1rem;
        }
    }

    &__sidenav {
        display: none;
        grid-area: sidenav;

        @include laptop-small {
            margin: 0;
            display: flex;
            flex-direction: column;
            width: 100%;
            position: sticky;
            top: 180px;
            height: calc(100vh - 220px);
            overflow: scroll;
            margin-bottom: 405px;
        }

        @include laptop {
            margin-right: 70px;
            top: 180px;
        }
    }

    &__content {
        margin: 0;
        width: 100%;
        grid-area: content;
        height: max-content;
        display: grid;
        grid-auto-rows: max-content;
        row-gap: 4rem;

        @include tablet {
            row-gap: 6rem;
        }
    }
}