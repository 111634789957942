@import "../../styles/baseline.scss";

.controller {
    width: max-content;
    //overflow: hidden;
    display: grid;
    grid-template-columns: 44px max-content 44px;
    gap: 4px;
    margin: auto;
    height: 44px;
    //border-radius: 4px;
    //border: 1px solid $black;
    //background-color: #ffffff16;
    //background: rgb(21, 21, 21);
    //border: 1px solid rgb(21, 21, 21);
    margin-top: 2rem;

    &__context {
        padding: 12px 24px;
        height: 44px;
        display: grid;
        justify-content: center;
        align-items: center;
        //background: #ffffff16;
        //background-color: rgb(36, 36, 36);
        border: 1px solid rgb(36, 36, 36);
        background-color: rgb(21, 21, 21);
        //background: $black;
    }

    &__back {
        //background: $black;
        height: 44px;
        display: grid;
        justify-content: center;
        align-items: center;
        //background-color: rgb(36, 36, 36);
        border: 1px solid rgb(36, 36, 36);
        background-color: rgb(21, 21, 21);
        border-radius: 4px 0px 0px 4px;

        &:hover {
            cursor: pointer;
            background-color: $black200;
        }

        & > svg {
            height: 24px;
            width: 24px;
            fill: whitesmoke;
        }
    }

    &__next {
        height: 44px;
        display: grid;
        justify-content: center;
        align-items: center;
        //background-color: rgb(36, 36, 36);
        //padding: auto;
        border: 1px solid rgb(36, 36, 36);
        background-color: rgb(21, 21, 21);
        border-radius: 0px 4px 4px 0px;

        &:hover {
            cursor: pointer;
            background-color: $black200;
        }
    
        & > svg {
            height: 24px;
            width: 24px;
            transform: scaleX(-1);
            fill: whitesmoke;
        }
    }
}