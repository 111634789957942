@import "../../styles/baseline.scss";

.header {
    display: flex;
    flex-direction: row;
    width: 100vw;
    transform: translateY(0);
    align-items: center;
    height: 64px;

    &__nav {
        width: 100%;
        margin: 0 16px;
        display: grid;
        grid-template-areas:    "wordmark . menu";
        grid-template-columns: min-content 1fr max-content;
        grid-template-rows: auto;
        column-gap: 1fr;
        justify-content: center;
        align-items: center;

        @include tablet {
            margin: 0 60px;
            grid-template-areas:    "wordmark . links . actions";
            grid-gap: 0.5rem;
            grid-template-columns: min-content 1fr max-content 1rem max-content;
        }

        @include laptop-small {
            margin: 0 100px;
        }

        @include laptop {
            width: 1000px;
            margin: 0 auto;
        }
    }

    &__wordmark {
        display: flex;
        flex-direction: row;
        grid-area: wordmark;
        align-self: center;
        
        & > h3 {
            color: whitesmoke;
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }

    &__actions {
        display: none;

        @include tablet {
            display: grid;
            grid-area: actions;
            width: max-content;
        }
    }

    &__menu {
        grid-area: menu;
        display: flex;
        width: max-content;

        @include tablet {
            display: none;
        }
    }

    &__drop {

        &.active {
            transform: scaleY(-1);
        }
    }

    &__links {
        display: none;

        @include tablet {
            display: grid;
            grid-area: links;
            align-items: center;
            grid-gap: 1.25rem;
            grid-template-columns: max-content max-content max-content;
        }
    }

        
    &__link {
        display: flex;
        flex-direction: row;
        width: max-content;
        padding: 12px 16px;
        border-radius: 6px;
        //height: 44px;
        justify-content: center;
        color: white;

        &.active {
            background-color: #181818;
            //background: rgba(14, 14, 14, 0.75);
        }

        &:hover {
            background-color: #181818;
            //background: rgba(14, 14, 14, 0.75);
        }

        & > svg {
            transition: 0.2s ease 0s;
            width: 14px;
            height: 14px;
            margin: auto 0;
            fill: white;
            margin-left: 0.5rem;
        }

        &:last-of-type {
            margin-right: 0px;
        }
    }

    &__cta {
        display: none;

        @include tablet {
            grid-area: cta;
        }
    }

    &__options {
        display: none;

        @include tablet {
            grid-area: options;
        }
    }

    &__overlay {
        background: rgba(0, 0, 0, .7);
        //opacity: 0.2;
        height: 100vh;
        width: 100vw;
        position: fixed;
        //z-index: 103;
    }
}