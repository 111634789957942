@import "../../styles/baseline.scss";

.subsection {
    height: max-content;
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 1.5rem;
    margin: 0;
    margin-top: 2.5rem;
    width: 100%;

    & > ul {
        //margin-bottom: 1.5rem;
        
        & > li {
            padding: 0.25rem 2rem;
            color: $grey;
            font-size: 1.15rem;
            line-height: 2.2rem;
            list-style-type: circle;
            list-style-position: outside;
            margin-left: 3rem;
        }
    }
}