@mixin mobile {
    @media screen and (min-width: 320px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin laptop-small {
    @media screen and (min-width: 1024px) {
        @content;
    }
}

@mixin laptop {
    @media screen and (min-width: 1200px) {
        @content;
    }
}


@mixin desktop {
    @media screen and (min-width: 1440px) {
        @content;
    }
}