@import "../../styles/baseline.scss";

.menu {
    width: 44px;
    height: 44px;
    display: grid;
    justify-content: center;
    align-content: center;
    background: rgba(14, 14, 14, 0);
    border-radius: 6px;
    border: 1px solid transparent;

    &:hover {
        background: rgba(14, 14, 14, 0.75);
        cursor: pointer;
    }

    & > svg {
        transition: 0.2s ease 0s;
        width: 32px;
        height: 32px;
        margin: auto 0;
        fill: $white;
    }
}