@import "../../styles/baseline.scss";

.sidenav {
    grid-area: sidenav;
    width: 100%;

    &__header {
        width: 100%;
        margin-bottom: 1rem;
    }

    &__links {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__link {
        padding: 4px 16px;
        width: 100%;
        border-radius: 6px;
        display: flex;
        border: 1px solid;
        border-color: $black;
        height: 44px;
        align-items: center;
        display: flex;

        &:hover {
            background-color: rgb(21, 21, 21);
            cursor: pointer;
        }

        &.active {
            background-color: rgb(40, 40, 40);
            border-color: rgb(36, 36, 36);
        }
    }
}