@import "../../styles/baseline.scss";

.pageheader {
    display: none;

    @include tablet {
        display: flex;
        flex-direction: row;
        width: 100%;
        transform: translateY(0);
        height: 56px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: #ffffff16;
    }

    &__container {
        width: 100%;
        margin: 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-items: middle;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;

        @include tablet {
            margin: 0 60px;
        }

        @include laptop-small {
            margin: 0 100px;
        }

        @include laptop {
            width: 1000px;
            margin: 0 auto;
        }
    }

    &__chevron {
        margin: auto;
        height: 24px;
        fill: whitesmoke;
        align-self: center;
        justify-self: center;
        //@include theme-aware('fill', 'link');
    }
}