@import "../../styles/baseline.scss";

.section {
    height: max-content;
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 1.5rem;
    margin: 0;
    width: 100%;

    & > h1 {
        margin-bottom: -1.5rem;
    }

    & > ul {
        
        & > li {
            padding: 0.25rem 2rem;
            color: $grey;
            font-size: 1.15rem;
            line-height: 2.2rem;
            list-style-type: circle;
            list-style-position: outside;
            margin-left: 3rem;
        }
    }

    &__marker {
        border-radius: 4px;
        padding: 8px 16px;
        width: max-content;
        //margin-bottom: 1rem;
    }
}

/*
.section {

    & > p, 
    h2 {
        margin-bottom: 1.5rem;
    }

    & > h3 {
        margin-bottom: 1rem;
    }

    & > ul {
        margin-bottom: 1.5rem;
        
        & > li {
            padding: 0.25rem 2rem;
            color: $grey;
            font-size: 1.15rem;
            line-height: 2.2rem;
            list-style-type: circle;
            list-style-position: outside;
            margin-left: 3rem;
        }
    }

    &__marker {
        border-radius: 4px;
        padding: 8px 16px;
        width: max-content;
        margin-bottom: 1rem;
    }
}

*/