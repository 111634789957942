@import "../../styles/baseline.scss";

.downloadcard {
    height: 460px;
    border-radius: 12px;
    border: 1px solid rgb(36, 36, 36);
    background-color: rgb(21, 21, 21);
    margin: 0 16px;
    overflow: hidden;
    margin-top: 2rem;

    &:hover {
        cursor: pointer;
        background-color: $black200;
    }

    @include tablet {
        margin-top: 0rem;
        margin: 0 60px;
    }

    @include laptop-small {
        margin: 0 100px;
    }

    @include laptop {
        margin: 0 auto;
        width: 1000px;
    }

    &__container {
        display: flex;
        height: 460px;
        width: 100%;
        justify-items: center;
        flex-direction: column;
        text-align: center;
        padding: 4rem 2rem;
        position: relative;

        @include tablet {
            padding: 4rem;
        }

        & > img {
            bottom: -6rem;
            //margin-top: 2rem;
            display: flex;
            width: 100%;
            max-width: 280px;
            border-radius: 6px;
            justify-self: center;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            //align-self: center;
    
            @include tablet {
                width: 520px;
            }
        }
    }
}