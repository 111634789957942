@import "../../styles/baseline.scss";

.main {

    &__hero {
        display: grid;
        height: 100vh;
        justify-content: center;

        @include tablet {
            justify-content: flex-start;
        }
    }

    &__details {
        grid-area: 'details';
        display: flex;
        flex-direction: column;
        margin: auto 0;
        padding: auto;
        width: 100%;
        max-width: 520px;

        @include tablet {
            max-width: 890px;
        }
    }

    &__heading {
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;

        @include tablet {
            text-align: start;
            max-width: 890px;
        }
    }

    &__description {
        display: flex;
        //padding-bottom: 40px;
        @include theme-aware('color', 'color-text-alt');
        width: 100%;
        font-weight: 500;
        text-align: center;
        padding-bottom: 1.5rem;
        max-width: 560px;

        @include tablet {
            padding-bottom: 3rem;
            text-align: left;
        }
    }

    &__actions {
        display: grid;
        grid-template-rows: repeat(2, max-content);
        row-gap: 1rem;
        width: 100%;
        margin: auto;

        & > a {
            width: 100%;
            max-width: 320px;
            justify-self: center;
        }

        @include tablet {
            justify-content: flex-start;
            margin: auto;
            grid-template-columns: repeat(2, max-content);
            column-gap: 1rem;
        }
    }

    &__projects {
        margin: auto;
        display: grid;
        grid-template-rows: max-content;
        row-gap: 3rem;
        margin-bottom: 5rem;

        @include tablet {
            row-gap: 2rem;
            margin-bottom: 3rem;
        }
    }
}


.promo {
    width: 100%;
    display: grid;
    grid-template-areas:    'preview'
                            'promo';
    grid-template-columns: 1fr;
    gap: 0rem;

    @include tablet {
        height: 800px;
        grid-template-areas: 'preview promo';
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        margin-bottom: 0rem;
    }

    &__preview {
        width: 100%;
        position: relative;
        grid-area: 'preview';
    }

    &__project {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 2.4rem;
    }

    &__details {
        display: flex;
        flex-direction: column;
        //vertical-align: center;
        margin: auto;
        align-items: center;
        max-width: 480px;
        width: 100%;

        & > a {
            width: 100%;
            max-width: 320px;
        }

        @include tablet {
            max-width: auto;
            align-items: flex-start;
        }
    }

    &__description {
        width: 80%;
        padding-bottom: 20px;
        text-align: center;

        @include tablet {
            width: 100%;
            text-align: left;
        }
    }

    &__link {
        width: 150px;
        height: 50px;
        margin-right: 25px;
        display: flex;
        flex-direction: row;
    }

    &__actions {
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        width: 100%;
        align-items: baseline;
    }

    &__redirect {
        height: 34px;
        padding: 10px 0px;
        margin: 8px 0px;
    }

    &__toggle {
        position: absolute;
        margin-top: 0px;
        bottom: 0;
        right: 0;
        background-color: transparent;
        border: none;
        outline: none;
        height: 80px;
        display: flex;
        //background-color: red;
        justify-content: space-around;

        @include laptop-small {
            left: 0;
            //top: 200;
            //margin-top: 620px;
            justify-content: space-around;
        }
    }

    &__player {
        width: 20px;

        &:hover {
            cursor: pointer;
        }
    }

    &__cta {
        @include theme-aware('color', 'link');
        font-size: 1rem;
    }

    &__button {
        display: flex;
        flex-direction: row;

        &:hover {
            opacity: 0.8;
        }

        &:hover > svg {
            padding-right: 12px;
            padding-left: 0px;
            transform: 0.3 ease;
        }
    }

    &__chevron {
        transform: scaleX(-1);
        padding-right: 8px;
        padding-left: 4px;
        margin: auto 0rem;
        height: 24px;
        @include theme-aware('fill', 'link');
    }
}

/*
    &__container {
        margin: 0 20px;
        height: 100vh;
        display: flex;
        vertical-align: center;
        padding: auto 0;
        flex-direction: column;
        justify-content: space-between;

        @include tablet {
            margin: 0 60px;
            display: flex;
            flex-direction: row;
        }

        @include laptop-small {
            margin: 0 100px;
        }

        @include laptop {
            margin: 0 auto;
            width: 1000px;
        }
    }
*/