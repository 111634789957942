@import "../../styles/baseline.scss";

.tile {
    //position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 420px;
    height: 160px;
    border-radius: 6px;
    background-color: rgb(21, 21, 21);
    //column-gap: 1rem;
    overflow: hidden;
    align-items: center;
    justify-self: center;

    &:hover {
        cursor: pointer;
        background-color: $black200;
    }

    &.mobile {
        padding: 1rem 1.5rem;
    }

    &.website {
        padding: 1rem 1.5rem;

        @include tablet {
            padding: 1rem 2.5rem;
        }
    }

    @include tablet {
        max-width: none;
        height: 100%;
    }

    & > div {
        height: calc(160px - 2rem);
        justify-content: center;
        display: flex;
        flex-direction: column;
        position: relative;

        @include tablet {
            height: 100%;
        }
    }

    &__preview {

        &--mobile {
            position: absolute;
            //transform: scale(0.85);
            max-width: 180px;
            top: 0.5rem;
            right: 1rem;

            @include tablet {
                max-width: 120px;
                width: 80%;
                right: 0em;
            }

            @include laptop-small {
                max-width: 120px;
                //width: 90%;
                right: 0rem;
            }
        }

        &--website {
            //width: 100%;
            height: max-content;
            //top: 0.5rem;
            //right: -2rem;
        }
    }
}