@import "../../styles/baseline.scss";

.redirect {
    width: 100%;
    color: whitesmoke;
    border: 1px solid rgb(36, 36, 36);
    background: rgba(14, 14, 14, 0.75);
    padding: 1.5rem 2rem;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
        background: $black100;
    }

    &__details {
        width: 100%;
    }

    &__chevron {
        height: 32px;
        width: 32px;
        fill: $white;
        transform: rotate(180deg);
    }
}