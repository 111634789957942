@import "../../styles/baseline.scss";

.dropdown {
    width: 100vw;
    border-color: #ffffff16;
    border-top: 1px solid #ffffff16;
    height: 100vh;
    position: relative;

    @include tablet {
        height: max-content;
        border-bottom: 1px solid #ffffff16;
    }

    &__container {
        display: grid;
        grid-template-areas: ' content ';
        row-gap: 0rem;
        padding: 0 16px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        height: calc(100vh - 256px);
        padding-bottom: 4rem;

        @include tablet {
            margin: 0 60px;
            height: 320px;
            grid-gap: 120px;
            padding: 0px;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            padding-bottom: 0rem;
        }

        @include laptop-small {
            margin: 0 100px;
            grid-template-areas: 'details content';
            grid-gap: 80px;
            grid-template-columns: 160px 1fr;
        }

        @include laptop {
            width: 1000px;
            margin: 0 auto;
            grid-template-areas: 'details content';
            grid-gap: 120px;
            grid-template-columns: 210px 1fr;
        }
    }

    &__details {
        display: none;

        @include laptop-small {
            display: flex;
            flex-direction: column;
            grid-area: details;
            text-align: left;
            padding: auto 0;
            margin: auto 0;
            width: 100%;

            & > h3, p {
                text-align: left;
            }
        }
    }

    &__drop {
        transition: 0.2s ease 0s;
        width: 14px;
        height: 14px;
        margin: auto 0;
        fill: white;
        margin-left: 0.5rem;

        &.active {
            transform: scaleY(-1);
        }
    }

    &__content {
        grid-area: content;
        display: grid;
        width: 100%;
        height: max-content;
        gap: 1rem;
        margin-top: 2rem;

        @include tablet {
            margin-top: 0rem;
            height: 320px;
        }

        &--fun {
            grid-template-rows:     ' header 1 '
                                    ' header 2 '
                                    ' content 2'
                                    ' header 3 ';

            @include tablet {
                grid-template-rows: 1fr;
            }
        }

        &--work {
            grid-template-rows:     ' header 1 '
                                    ' content 1'
                                    ' header 2 '
                                    ' header 3 ';

            @include tablet {
                grid-template-rows: 1fr;
            }
        }

        &--none {
            grid-template-rows:     ' header 1 '
                                    ' header 2 '
                                    ' header 3 ';

            @include tablet {
                grid-template-rows: 1fr;
            }
        }
    }

    &__section {
        //display: grid;
        gap: 1rem;
        width: 100%;
        height: max-content;

        @include tablet {
            height: 100%;
            padding: 1rem 0rem;
        }

        &.active {
            display: grid;
        }

        &.hidden {
            display: none;
        }

        &--fun {
            grid-area: 'content 2';
            grid-auto-flow: row;
            gap: 1rem;

            @include tablet {
                padding: 1rem 0rem;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
            }
        }

        &--work {
            grid-area: 'content 1';
            grid-auto-flow: row;
            gap: 1rem;

            @include tablet {
                padding: 1rem 0rem;
                grid-template-columns: 1fr;
            }
        }
    }

    &__name {
        text-align: left;
        align-items: center;
        padding: auto 0;
        margin: auto 0;
        width: 100%;
        height: auto;
    }

    &__wrapper {
        position: relative;
    }

    &__overlay {
        position: absolute;
        width: 110px;
        //height: 80px;
        top: 7rem;
        left: 10rem;
        right: 0;
        bottom: 0;
        margin: auto;
        padding: auto;
        z-index: 9;
    }

    &__project {
        font-weight: bold;
        @include theme-aware('color', 'color-text');
        font-size: 1rem;
    }

    &__actions {
        border-color: #ffffff16;
        border-top: 1px solid #ffffff16;
        //backdrop-filter: blur(32px);
        //-webkit-backdrop-filter: blur(32px);
        //background: rgba(14, 14, 14, 1);
        //background: linear-gradient(rgba(14, 14, 14, 0.65), rgba(14, 14, 14, 1));
        height: max-content;
        display: grid;
        grid-auto-rows: max-content;
        row-gap: 1rem;
        z-index: 1000;
        margin: 0 16px;
        padding: 1.5rem 1rem 2.5rem;
        padding-bottom: 2.5rem;
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100vw;

        & > a {
            max-width: 420px;
            justify-self: center;
        }

        @include tablet {
            display: none;
        }
    }

    &__heading {
        display: flex;
        width: 100%;
        max-width: 420px;
        justify-self: center;
        height: 48px;
        align-items: center;

        @include tablet {
            display: none;
            max-width: auto;
        }
    }
}

/*
@import "../../styles/baseline.scss";

.dropdown {
    width: 100vw;
    border-color: #ffffff16;
    border-top: 1px solid #ffffff16;
    height: 100vh;
    position: relative;

    @include tablet {
        height: max-content;
        border-bottom: 1px solid #ffffff16;
    }

    &__container {
        //display: none;
        display: grid;
        //grid-auto-flow: row;
        //grid-template-columns: 1fr;
        grid-template-areas: 'content';
        row-gap: 0rem;
        margin: 0 16px;

        @include tablet {
            margin: 0 60px;
            height: 320px;
            //display: grid;
            grid-template-areas: 'content';
            grid-gap: 120px;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }

        @include laptop-small {
            margin: 0 100px;
            grid-template-areas: 'details content';
            grid-gap: 80px;
            grid-template-columns: 160px 1fr;
        }

        @include laptop {
            width: 1000px;
            margin: 0 auto;
            grid-template-areas: 'details content';
            grid-gap: 120px;
            grid-template-columns: 210px 1fr;
        }
    }

    &__details {
        display: none;

        @include laptop-small {
            display: flex;
            flex-direction: column;
            grid-area: details;
            text-align: left;
            padding: auto 0;
            margin: auto 0;
            width: 100%;

            & > h3, p {
                text-align: left;
            }
        }
    }

    &__new {
        width: 360px;
        position: absolute;
        right: 2rem;
        //right: -8rem;
    }

    &__content {
        grid-area: content;
        display: grid;
        width: 100%;
        height: 100%;
        gap: 1rem;
        padding: 1rem 0rem;

        &.fun {
            grid-auto-flow: row;

            @include tablet {
                grid-template-columns: 1fr 1fr;
            }
        }

        &.work {
            grid-auto-flow: row;

            @include tablet {
                grid-template-columns: 1fr;
            }
        }
    }

    &__card {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        //border: 1px solid rgb(36, 36, 36);
        background-color: rgb(21, 21, 21);
        justify-content: center;
        //margin: 0 auto;
        overflow: hidden;

        &--fun {
            padding: 2rem 1.5rem;
        }

        &--work {
            padding: 2rem 2.5rem;
        }


        &:hover {
            background-color: $black300;
            cursor: pointer;
        }
    }

    &__name {
        text-align: left;
        align-items: center;
        padding: auto 0;
        margin: auto 0;
        width: 100%;
        height: auto;
    }

    &__wrapper {
        position: relative;
    }

    &__overlay {
        position: absolute;
        width: 110px;
        //height: 80px;
        top: 7rem;
        left: 10rem;
        right: 0;
        bottom: 0;
        margin: auto;
        padding: auto;
        z-index: 9;
    }

    &__video {
        width: 94px;
        position: absolute;
        top: 7rem;
        left: 10rem;
        right: 0;
        bottom: 0;
        margin: auto;
        padding: auto;
        z-index: 8;
    }



    &__item {
        transition: 0.2s ease;
        margin: 10px 0px;
        display: flex;
        flex-direction: column;
        text-align: center;
        vertical-align: bottom;
        padding: 18px 0;
        border-radius: 4px;

        &--design {
            width: 240px;
        }

        &--development {
            width: 100%;
        }

        &:hover {
            background-color: #cacaca1f;
        }
    }

    &__promo {
        position: relative;

        &--website {
            margin: 0 20px;
            padding: 0 auto;
            display: flex;
            justify-content: space-around;
        }

        &--app {
            justify-content: center;
            margin: 0 auto;
            padding: 0 auto;
        }
    }

    &__project {
        font-weight: bold;
        @include theme-aware('color', 'color-text');
        font-size: 1rem;
    }

    &__actions {
        border-color: #ffffff16;
        border-top: 1px solid #ffffff16;
        backdrop-filter: blur(32px);
        -webkit-backdrop-filter: blur(32px);
        background: rgba(14, 14, 14, 0.65);
        height: max-content;
        display: grid;
        grid-auto-rows: max-content;
        row-gap: 1rem;
        z-index: 1000;
        //margin: 0 16px;
        padding: 1.5rem 1rem 2.5rem;
        //padding-bottom: 2.5rem;
        position: fixed;
        bottom: 64px;
        width: 100vw;

        @include tablet {
            display: none;
        }
    }
}
*/