@import "../../styles/baseline.scss";

.carousel {
    width: 100%;
    //margin: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;

    &__video,
    &__image {
        width: 100%;
        border-radius: 6px;
    }

    &__controller {
        width: max-content;
        overflow: hidden;
        display: grid;
        gap: 1px;
        margin: auto;
        height: 44px;
        margin-top: 2rem;
    }

    &__toggle {
        padding: 12px 24px;
        background: $black;

        &.active {
            background: #383838d9;
        }
    }
}